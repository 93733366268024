/*html,body { 
    font: 12px "Lucida Grande", Sans-Serif;
}*/
html{
	min-height:100%;
	position:relative;
}
body {
    background: #222d32;/*url(../img/bg.png); */
	/*padding-bottom:40px;*/
	/*height: calc(100% - 20px);*/
}
/*h1{ 
    color: #fa9f00;
    font: 4em Ubuntu,Helvetica, Sans-Serif;
}*/

/*===== general css ======*/
.nopadding{
    padding:0;
}
.nomargin{
    margin:0;
}
.padding{
    padding:5px;
}
.spacearound{
    margin:3px;
}
.space{
    margin-top:5px;
    margin-bottom:5px;
}
.spacetop{
    margin-top:15px;
}
.spacebot{
    margin-bottom:15px;
}
.padding-left{
    padding-left:10px;
}
.padding-right{
    padding-right:10px;
}
.no-padding-bot{
    padding-bottom:0px;
}
.no-padding-top{
    padding-top:0px;
}
.no-margin-bot{
    margin-bottom:0px;
}
.no-margin-top{
    margin-top:0px;
}
.margin-sides{
    margin: 0 5px;
}
.huge{
    margin:25px 15px;
}
.relative{
    position:relative;
}
.absolute{
    position:absolute;
}
.static{
    position: static!important;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: rgba(255,255,255,0.7);
    border-radius: 3px;
}
.max100{
    max-width:100%;
}
.overlay >.fa{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: #000;
    font-size: 30px;
}
.nowrap{
    white-space:nowrap;
}
.wrap{
    white-space:normal;
}
.link{
    color:inherit;
    text-decoration:none;
    cursor:pointer;
}
.link:hover{
    color:inherit;
    font-weight:bold;
}
.link:active,.link:focus{
    outline:none;
    color:inherit;
}
@media only screen and (max-width:480px){
    #wrapper{
        padding-right:0px;
        padding-left:0px;
    }
    h1{
        text-align:center;
    }
    .col-xxs-12{
        width:100%;
        text-align:center
    }
}
.inline{
    display:inline;
    vertical-align:middle;
}
.inline-block{
    display:inline-block;
}
.middle{
    vertical-align : middle;
}
.top{
    vertical-align : top;
}
.bot{
    vertical-align : bottom;
}

/*footer{
    position:absolute;
    bottom:0;
    right:0;
    text-align:center;
    height:40px;
    color:white;
    width:100%;
}*/

/*====== file specific css ======*/
tfoot input {
    width: 100%;
    padding: 3px;
    box-sizing: border-box;
}

.details > p{
    margin:0;
    margin-right:52px;
}
.user {
    font-weight: bold;
}

.user-image{
    border-radius:50%;
    width:50px;
    display:inline-block;
    margin:2px 0;
}
.user-name{
    display:inline-block;
    vertical-align:middle;
    white-space:normal;
    max-width:90%; /* fallback for browsers without support for calc() */
    max-width: -webkit-calc(100% - 50px);
    max-width: -moz-calc(100% - 50px);
    max-width: calc(100% - 50px);
	min-width:80px;
}
.msg {
    margin-bottom: 10px;
    overflow: hidden;
}
.temp_hide{
    display:none;
}
.time {
    float: right;
    color: #939393;
}
.white{
    color:white;
}
#leave-room{
    top: -10px;
	right: 0;
}
.productImg{
    width:100px; 
    max-width:100%;  
} 
.hiddenfile {
 width: 0px;
 height: 0px;
 overflow: hidden;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    vertical-align: middle;
}
/*#datatable td{ 
    max-height:50px;
    max-width:200px;
    overflow:scroll;
}*/
/*.dataTables_scrollBody::-webkit-scrollbar { 
    display: none; 
}*/
#readMSG{
    width:100%;
    height:400px;
}
.mailbox-attachment-name .fa-paperclip{
    position: absolute;
    font-size: 2em;
}
.mailbox-attachment-name span.attach-name{
    float: right;
    word-wrap: break-word;
    /* overflow: hidden; */
    max-width: calc(100% - 30px);
    margin-left: 30px;
    display: block;   
}
.user-body{
    font-size:12px;
    font-weight:normal;
}
.dataTables_wrapper{
    border-top:none;
}
.max_td{
    text-overflow: ellipsis;
    white-space: nowrap;
}
.table>thead>tr>th.max_td{
    padding:4px 30px 7px 3px;
    white-space: nowrap;
}
#db_dataTable > tbody > tr.child > td > ul{
    width:100%;
}
#db_dataTable > tbody > tr.child > td > ul > li > span:nth-child(1){
    padding:5px;
    text-align:right;
}
#db_dataTable > tbody > tr.child > td > ul > li > span:nth-child(2){
    padding:5px;
    text-align:left;
}
/*table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before{
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
}*/

.ellipsis{
    max-width:120px;
    overflow:hidden;
}
.todo-list{
    overflow:visible;
}
.temaxia{
    display:none;
}
.withtemaxia .temaxia{
    display: block;
}
tr.commited {
	background-color: #98d058!important;
}
tr.commited.selected {
	background-color: #08C!important;
}
@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
   max-width:1200px;
  }
}
#customer_modal div.dataTables_wrapper div.dataTables_paginate{
    text-align:center;
}